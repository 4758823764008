@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');

$primary: #333333;
$secondary: #C99632;
$tertiary: #E2A83E;
$color-text: #878C96;

body{
  font-family: 'Montserrat', sans-serif;
}

body.overflow{
  overflow: hidden;
}
.f-light{
  font-weight: 300;
}
.f-medium{
  font-weight: 500;
}
.f-bold{
  font-weight: 700;
}
section{
  padding-top: 50px;
  @media (min-width: 992px){
    padding-top: 80px;
  }
}
.heading--title{
  font-size: 24px;
  color: $primary;
  margin-bottom: 40px;
  @media (min-width: 992px){
    font-size: 36px;
    margin-bottom: 50px;
  }
  @media (min-width: 1349px){
    font-size: 34px;
  }
}

.divider{
  position: relative;
  width: 100%;
  &::after{
    position: absolute;
    content: '';
    background-color: $secondary;
    @media (min-width: 992px){

    }
  }
  &.large{
    margin-top: 47px;
    &::after{
      width: 40px;
      height: 2px;
      left: calc(50% - 20px);
      bottom: -2px;
      @media (min-width: 992px){
        width: 80px;
        height: 4px;
        left: calc(50% - 40px);
        margin-top: 69px;
        bottom: -4px; 
      }
    }
  }
  &.light{
    margin-top: 39px;
    &::after{
      width: 0;
      height: 0;
      @media (min-width: 992px){
        width: 40px;
        height: 2px;
        left: calc(50% - 20px);
        bottom: -2px; 
      }
    }
  }
}
.position-relative{
  position: relative;
}
/* Slider */
.slick-slider{
  .slick-prev{
    width: 25px;
    height: 25px;
    left: 15px;
    z-index: 1;
    background: url(http://dev.somosdorika.com/images/dorika-arrows-left.png);
    opacity: 1;
    @media (min-width: 992px){
      width: 40px;
      height: 40px;
    }
    &::before{       
      display: none; 
    }
    &:hover{
      background: url(http://dev.somosdorika.com/images/dorika-arrows-left.png) !important;
    }
    &:focus{
      background: url(http://dev.somosdorika.com/images/dorika-arrows-left.png) !important;
      outline: none;
    }
  }
  .slick-next{
    width: 25px;
    height: 25px;
    right: 15px;
    background: url(http://dev.somosdorika.com/images/dorika-arrows-right.png);
    opacity: 1;
    @media (min-width: 992px){
      width: 40px;
      height: 40px;
    }
    &::before{    
      display: none; 
    }
    &:hover{
      background: url(http://dev.somosdorika.com/images/dorika-arrows-right.png) !important;
    }
    &:focus{
      background: url(http://dev.somosdorika.com/images/dorika-arrows-right.png) !important;
      outline: none;
    }
  }
}
.mobile{
  @media (min-width: 992px){
    display: none;
  }
}

.desktop{
  display: none;
  @media (min-width: 992px){
    display: block;
  }
}