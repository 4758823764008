.form{
  margin-bottom: 100px;
  .form-group{
    color: $primary;
    font-size: 16px;
    margin-bottom: 30px;
    @media (min-width: 992px){
      font-size: 18px;
      margin-bottom: 42px;
    }
    @media (min-width: 1200px){
      font-size: 18px;
    }
    @media (min-width: 1348px){
      font-size: 16px;
    }
    .label{
      margin-bottom: 0;
    }
    .form-control{
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid $tertiary;
      &:focus{
        box-shadow: none !important;
      }
    }
    .btn-primary{
      background-color: $primary;
      padding: 15px 50px;
      border: 0;
      font-size: 14px;
      @media (min-width: 992px){
        
      }
      &:hover{
        background-color: $primary;
        opacity: 0.9;
      }
      &:focus{
        box-shadow: none !important;
      }
      &:active{
        box-shadow: none !important;
        background-color: $primary;
        opacity: 0.9;
      }
      .spinner-border{
        width: 1rem;
        height: 1rem;
        border-width: 1px;
        margin-right: 10px;
        margin-bottom: 1px;
      }
    }
  }
}