.proyectos{
  .project{
    margin-bottom: 75px;
    @media (min-width: 992px){
      margin-bottom: 100px;
    }
    &--left{
      .tag{
        right: 0;
        @media (min-width: 992px){
          right: 15px;
        }
      }
    }
    &--right{
      .tag{
        left: 0;
        @media (min-width: 992px){
          left: 15px;
        }
      }
    }
    &__text{
      color: $color-text;
      @media (min-width: 992px){
        padding-top: 32px;
      }
      &--address{
        font-size: 16px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        @media (min-width: 992px){
          margin-bottom: 32px;
        }
        @media (min-width: 1200px){
          font-size: 14px;
        }
        span{
          display: inline-block;
          img{
            display: block;
          }
        }
      }
      &--description{
        font-size: 14px;
        @media (min-width: 992px){
          font-size: 14px;
        }
        @media (min-width: 1200px){
          font-size: 13px;
        }
        p{
          &:last-child{
            @media (min-width: 992px){
              margin-bottom: 0;
            }
          }
        }
        strong{
          font-weight: 600;
        }
      }
    }
    .col-lg-8{
      padding-left: 0;
      padding-right: 0;
      height: 300px;
      @media (min-width: 992px){
        padding-left: 15px;
        padding-right: 15px;
        height: auto;
        max-height: 530px;
      }
      .tag{
        background: $secondary;
        color: #FFFFFF;
        font-size: 14px;
        padding: 12px 27px;
        display: inline-block;
        font-weight: 500;
        position: absolute;
        top: 0;
        z-index: 1;
        @media (min-width: 992px){
          font-size: 17px;
          padding: 15px 35px;
        }
        @media (min-width: 1200px){
          font-size: 14px;
        }
      }
      /* Slider */
      .slick-slider{
        height: 100%;
        .content-slide{
          position: relative;
          overflow: hidden;
          display: flex !important;
        }
        div{
          height: 100%;
        }
        .bg-black{
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          z-index: -1;
        }
        .bg-overlay{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, .9);
          z-index: -1;
        }
        img{
          max-height: 100%;
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }
}