.navbar{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 60px;
  background-color: transparent;
  transition: all .5s;
  @media (min-width: 768px){
    height: 80px;
    background-color: transparent;
  }
  &.active{
    background-color: $primary;
    transition: all .5s;
  }
  .row{
    width: calc(100% + 30px);
  }
  .navbar-brand{
    color: #FFFFFF;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem;
    img{
      width: 96px;
    }
    @media (min-width:992px){

    }
  }
  .navbar-toggler{
    @media (min-width: 768px){
      display: none;
    }
    .navbar-toggler-icon{
      background-color: white;
    }
  }
  .navbar-collapse{
    position: absolute;
    width: 100vw;
    top: -150px;
    right: 0;
    transition: all .3s;
    @media (min-width: 768px){
      display: flex;
      position: relative;
      top: 0;
    }
    .navbar-nav{
      width: 100%;
      text-align: center;
      padding-top: 8px;
      background-color: #FFFFFF;
      @media (min-width: 768px){
        background-color: transparent;
        padding-top: 0;
        width: auto;
      }
      .nav-item{
        color: $primary;
        width: 100%;
        cursor: pointer;
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $tertiary;
        @media (min-width: 768px){
          margin-right: 65px;
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: 0;
          width: auto;
          color: #FFFFFF;
        }
        &:last-child{
          margin-right: 0;
        }
        &::after{
          position: absolute;
          content: '';
          width: 0;
          height: 3px;
          background-color: $tertiary;
          left: 0;
          bottom: -10px;
          transition: all .3s;
        }
        &:hover{
          &::after{
            @media (min-width: 768px){
              transition: all .3s;
              width: 25px;
            }
          }
        }
        &.active{
          &::after{
            @media (min-width: 768px){
              transition: all .3s;
              width: 25px;
            }
          }
        }
      }
    }
    &.active{
      top: 50px;
      @media (min-width: 768px){
        top: 0;
      }
    }
  }
}
/* Footer */
.footer{
  background-image: linear-gradient(180deg, #333333 0%, #1E1E1E 100%);
  padding: 60px 15px 50px 15px;
  &__top{
    &--logo{
      width: 175px;
      margin-bottom: 36px;
    }
    &--info{
      padding-left: 0;
      margin-bottom: 50px;
      li{
        margin-bottom: 10px;
        list-style: none;
        font-size: 18px;
        font-weight: 400;
        @media (min-width: 1348px){
          font-size: 16px;
        }
        a{
          color: #FFFFFF;
          display: flex;
          align-items: center;
          span{
            display: inline-block;
            margin-right: 16px;
            min-width: 25px;
            img{
              display: block;
            }
          }
        }
      }
    }
    &--rrss{
      padding-left: 0;
      margin-bottom: 75px;
      @media (min-width: 1367px){
        margin-bottom: 50px;
      }
      li{
        list-style: none;
        margin-right: 40px;
        display: inline-block;
      }
    }
  }
}