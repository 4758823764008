.proyectos-realizados{
  .row{
    @media (min-width: 992px){
      padding-right: 15px;
      padding-left: 15px;
    }
    .item{
      height: 200px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      @media (min-width: 992px) {
        height: 365px;
      }
      &__image{
        object-fit: cover;
        object-position: center;
        display: block;
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
        &:hover{
          filter: grayscale(0%);
        }
      }
      &__name{
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        background-color: rgba(255, 255, 255, 0.95);
        border: 2px solid $secondary;
        border-left: 0;
        position: absolute;
        bottom: 20px;
        left: 0;
        width: auto;
        padding: 10px 20px 10px 12px;
        @media (min-width: 992px) {
          padding: 14px 25px 15px 12px;
          font-size: 21px;
          font-weight: 400;
          bottom: 30px;
        }
      }
    }
    .popup{
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      padding-top: 25px;
      display: none;
      &.active{
        position: fixed;
        z-index: 1050;
        width: 100%;
        height: 100%;
        display: flex;
      }
      .container{
        margin-top: auto;
        margin-bottom: auto;
      }
      &__content{
        background-color: #FFFFFF;
        border-radius: 16px 16px 0px 0px;
        padding: 17px 0 15px;
        max-height: 96vh;
        margin-right: 0;
        margin-left: 0;
        overflow: auto;
        @media (min-width: 992px){
          max-height: 93vh;
          margin-right: 80px;
          margin-left: 80px;
          border-radius: 4px;
          overflow: hidden;
        }
        &--gallery{
          padding-top: 33px;
          height: 300px;
          @media (min-width: 992px){
            padding-top: 0;
            height: 100%;
          }
          .slick-slider{
            height: 100%;
            div{
              height: 100%;
            }
            .content-slide{
              position: relative;
              overflow: hidden;
              display: flex !important;
              background-color: black;
              img{
                max-height: 100%;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
                margin-top: auto;
                margin-bottom: auto;
              }
            }
          }
          .slick-dots{
            li{
              -ms-flex-preferred-size: 0;
              flex-basis: 0;
              -ms-flex-positive: 1;
              flex-grow: 1;
              max-width: 100%;
              width: auto;
              height: auto;
              border: 3px solid transparent;
              margin: 0 1px;
              &:first-child{
                margin-left: 0;
              }
              &:last-child{
                margin-left: 0;
              }
              &.slick-active{
                border-color:  #C99632;
              }
              img{
                max-width: 100%;
              }
            }
          }
        }
        &--text{
          color: #878C96;
          font-weight: 300;
          @media (min-width: 992px){
            overflow: auto;
            max-height: 85vh;
          }
          h4{
            font-size: 22px;
            font-weight: 400;
            color: $primary;
          }
          .divider{
            &.light{
              margin-top: 22px;
              &::after{
                width: 20px;
                height: 2px;
                left: 0;
              }
              @media (min-width: 992px){
                margin-top: 18px; 
              }
            }
            &.mobile{
              &::after{
                width: 20px;
                height: 2px;
              }
            }
          }
          .description{
            font-size: 12px;
            padding-top: 22px;
            ul{
              padding-left: 15px;
            }
            strong{
              font-weight: 600;
            }
          }
          .details{
            font-size: 12px;
            padding-left: 0;
            li{
              list-style: none;
              strong{
                font-weight: 600;
              }
            }
          }
        }
        .button-close{
          margin-bottom: 10px;
          cursor: pointer;
          margin-top: -3px;
          img{
            display: flex;
            margin-left: auto;
          }
          
        }
      }
    }
  }
}