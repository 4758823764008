@import './resets', './globals', '_layout', '_projects', '_oldprojects', '_form';
/* Header */
header{
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  .banner-image{
    display: block;
    object-fit: cover;
    object-position: center;
    height: 100vh;
    margin-left: -140%;
    @media (min-width: 420px){
      margin-left: -90%;
    }
    @media (min-width: 576px){
      margin-left: -30%;
    }
    @media (min-width: 800px){
      margin-left: 0%;
    }
    @media (min-width: 992px){
      
    }
    @media (min-width: 1348px){
      max-width: 100%;
      height: auto;
    }
    @media (min-width: 1367px){
      width: 100%;
    }
  }
  .banner-text{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg, rgba(51, 51, 51, 0.6) 0%, rgba(51, 51, 51, 0) 100%);
    h1{
      font-size: 40px;
      color: #FFFFFF;
      padding-top: 130px;
      @media (min-width: 800px){
        padding-top: 100px;
      }
      @media (min-width: 992px){
        font-size: 68px;
        padding-top: 75px;
      }
      @media (min-width: 1200px) {
        font-size: 82px;
      }
      span{
        display: inline-block;
      }
    }
  }
}
/* Nosotros */
.nosotros{
  .heading--title{
    margin-bottom: 20px;
    @media (min-width: 992px) {
      margin-bottom: 30px;
    }
  }
  .heading--subtitle{
    font-size: 14px;
    line-height: 25px;
    color: $color-text;
    @media (min-width: 992px){
      font-size: 16px;
      line-height: 27px;
    }
    strong{
      font-weight: 600;
    }
  }
}
/* Contacto */
.contacto{
  .heading--title{
    margin-bottom: 20px;
    @media (min-width: 992px){
      margin-bottom: 30px;
    }
  }
  .heading--subtitle{
    color: $color-text;
    font-size: 14px;
    @media (min-width: 992px) {
      font-size: 18px;
      margin-bottom: 75px;
    }
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
  &__map{
    overflow: hidden;
    img{
      width: 330%;
      margin-left: -115%;
      @media (min-width: 768px){
        width: 180%;
        margin-left: -41%;
      }
      @media (min-width: 992px){
        width: 100%;
        margin-left: 0%;
      }
    }
  }
}